import React from 'react'

import './styles/index.scss';

export default function DetailsHeader() {
    return (
        <div className="details__header">
            ADSOUL
        </div>
    )
}
